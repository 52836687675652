import BaseForm from '../BaseForm'

import React from 'react'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import {default as TabContent} from '../../../Components/General/Tab'
import { Modal, Button, Form, Row } from 'react-bootstrap'
import SearchInputObj from '../../../Components/General/SearchInputs/searchInputObj'
import Map from '../../../Components/Map/Map'
import FormControlsRender from '../../../Utils/FormControlsRender'
import { Numeric, Text, TextArea } from '../../../Utils/FormControlsPresets'
import AbonentForm from '../abonents/AbonentForm'
import CounterForm from '../counters/CounterForm'
import Counter from '../../../Components/Devices/Counter'
import ValveNbiotForm from '../valves_nbiot/ValveNbiotForm'
import ValveNbiot from '../../../Components/Devices/ValveNbiot'
import ValveForm from '../valves/ValveForm'
import Valve from '../../../Components/Devices/Valve'
import SensorForm from '../sensors/SensorForm'
import Sensor from '../../../Components/Devices/Sensor'
import LeakageForm from '../leakages/LeakageForm'
import LeakageSensor from '../../../Components/Devices/LeakageSensor'
import ConfirmModal from '../../../Components/General/ConfirmModal'
import Zone from '../../../Components/Zone'

import { permissions, canRead } from '../../ServerPermissions/Permissions'

export default class ZoneForm extends BaseForm {

  constructor(props) {
    super(props)

    this.isUserZoneAdmin = this.props.serverPermissions?.isZoneAdmin

    this.state = {
      requestProcess: false,
      card: {},
      formControls: {
        TITLE: Text('Наименование'),
        SHORTTITLE: Text('Краткое наименование'),
        COMMENT: TextArea('Служебный комментарий'),
        LATITUDE: Numeric('Коор. (широта)', -90, 90, /^\-?\d{0,2}(\.?\d{0,6})?$/), //eslint-disable-line
        LONGITUDE: Numeric('Коор. (долгота)', -180, 180, /^\-?\d{0,3}(\.?\d{0,6})?$/), //eslint-disable-line
        SORT: Numeric('Порядок сортировки'),
        PARENT: {},
      },
      postData: {},
      mapModalShow: false,
      abonentModalShow: false,
      counterModalShow: false,
      valveModalShow: false,
      valveNbiotModalShow: false,
      leakageModalShow: false,
      sensorModalShow: false,
      thisTabId: null
    }

    this.id = props.id //для обновления ID
  }

  clickModalOpen = (modalVariableShow, id = null) => {
    this.setState({ thisTabId: id, [modalVariableShow]: true })
  }

  hideModals = () => {
    this.setState({
      thisTabId: null,
      counterModalShow: false,
      abonentModalShow: false,
      valveModalShow: false,
      valveNbiotModalShow: false,
      sensorModalShow: false,
      leakageModalShow: false,
    })
  }

  render() {
    return (
      <>
        <Modal.Header closeButton>
          <Modal.Title>{(this.state.card.ID ? 'Редактирование' : 'Создание') + ' зоны'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              {FormControlsRender('TITLE', this.state.formControls, this.onChangeHandler, this.onSaveHandler(), 'col-md-8')}
              {FormControlsRender('SHORTTITLE', this.state.formControls, this.onChangeHandler, this.onSaveHandler(), 'col-md-4')}
            </Row>
            <Row>
              <SearchInputObj
                label="Родительская зона"
                source="zone"
                server={this.props.server}
                render={item => <Zone item={item} />}
                onSelectItem={item => {
                  this.setState({
                    postData: Object.assign({}, this.state.postData, { PARENT: item.ID ?? null })
                  })
                }}
                defaultItem={{
                  ID: this.state.card.PARENT,
                  TITLE: this.state.card.PARENT_TITLE,
                  TYPE_POINT_IMAGE_NUMBER: this.state.card.PARENT_POINT_IMAGE_NUMBER
                }}
                errorMessage={this.state.formControls.PARENT?.errorMessage}
              />

              <SearchInputObj
                label="Тип"
                source="type-point"
                server={this.props.server}
                render={item =>
                  <><span className={`app-icons app-icon-${item.IMAGE_NUMBER} app-icons-top`}> </span>{item.TITLE}</>
                }
                onSelectItem={item => {
                  this.setState({
                    postData: Object.assign({}, this.state.postData, { TYPE_POINT: item.ID ?? null })
                  })
                }}
                defaultItem={{
                  ID: this.state.card.TYPE_POINT,
                  TITLE: this.state.card.TYPE_POINT_TITLE,
                  IMAGE_NUMBER: this.state.card.TYPE_POINT_IMAGE_NUMBER
                }}
              />

              <SearchInputObj
                label="Источник погоды"
                source="weather-source"
                server={this.props.server}
                onSelectItem={item => {
                  this.setState({
                    postData: Object.assign({}, this.state.postData, { WEATHER_SOURCE: item.CITY_ID ?? null })
                  })
                }}
                defaultItem={{
                  CITY_ID: this.state.card.WEATHER_SOURCE,
                  TITLE: this.state.card.WEATHER_SOURCE_TITLE
                }}
              />
            </Row>
            <Row>
              {FormControlsRender('LATITUDE', this.state.formControls, this.onChangeHandler, this.onSaveHandler(), 'col-md-4')}
              {FormControlsRender('LONGITUDE', this.state.formControls, this.onChangeHandler, this.onSaveHandler(), 'col-md-4')}
              <div className="col-md-1"><Button variant="secondary" style={{ marginTop: "32px" }} onClick={this.showMapModal()}>Карта</Button></div>
              {FormControlsRender('SORT', this.state.formControls, this.onChangeHandler, this.onSaveHandler(), 'col-md-3')}
            </Row>
            <Row>
              {FormControlsRender('COMMENT', this.state.formControls, this.onChangeHandler, this.onSaveHandler(), 'col-md-12')}
            </Row>

            <div style={{ height: '15px' }}></div>

            <Tabs
              id="zone-tabs"
              className="mb-3"
            >
              {canRead(permissions.counter, this.props.serverPermissions) ?
                <Tab eventKey="counters" title="Приборы учёта">
                  <TabContent 
                    cards={this.state.card?.COUNTERS ?? []}
                    onChangeHandler={() => this.loadAndParentReload(this.id, ['COUNTERS'])}
                    renderFunction={item => <Counter item={item} onClick={this.isUserZoneAdmin ? null : this.clickModalOpen.bind(this, 'counterModalShow', item.ID)} />}
                    renderSearchFunction={item => <Counter item={item} />}
                    addUrl={`retranslate/${this.props.server}/zone-counter`}
                    addDataIn={item => ({ OWNER: this.id, COUNTER: item.ID })}
                    deleteUrl={this.isUserZoneAdmin && (this.state.card?.COUNTERS ?? []).length < 2 ? null :
                      item => (`retranslate/${this.props.server}/zone-counter/${item.ID_LINK}`)}
                    searchUrl={`retranslate/${this.props.server}/counter`}
                    filter={{ noZone: this.id ?? null }}
                    disabled={!this.id}
                  />
                </Tab> : null}
              {canRead(permissions.sensor, this.props.serverPermissions) ?
                <Tab eventKey="sensors" title="Датчики">
                  <TabContent 
                    cards={this.state.card?.SENSORS ?? []}
                    onChangeHandler={() => this.loadAndParentReload(this.id, ['SENSORS'])}
                    renderFunction={item => <Sensor item={item} onClick={this.isUserZoneAdmin ? null : this.clickModalOpen.bind(this, 'sensorModalShow', item.ID)} />}
                    renderSearchFunction={item => <Sensor item={item} />}
                    addUrl={`retranslate/${this.props.server}/zone-sensor`}
                    addDataIn={item => ({ ZONE_ID: this.id, SENSOR_ID: item.ID })}
                    deleteUrl={this.isUserZoneAdmin && (this.state.card?.SENSORS ?? []).length < 2 ? null :
                      item => (`retranslate/${this.props.server}/zone-sensor/${item.ID_LINK}`)}
                    searchUrl={`retranslate/${this.props.server}/sensor`}
                    filter={{ noZone: this.id ?? null }}
                    disabled={!this.id}
                  />
                </Tab> : null}
              {canRead(permissions.leakageSensor, this.props.serverPermissions) ?
                <Tab eventKey="leakageSensors" title="Датчики протечки">
                  <TabContent
                    cards={this.state.card?.LEAKAGES ?? []}
                    onChangeHandler={() => this.loadAndParentReload(this.id, ['LEAKAGES'])}
                    renderFunction={item => <LeakageSensor item={item} onClick={this.isUserZoneAdmin ? null : this.clickModalOpen.bind(this, 'leakageModalShow', item.ID)} />}
                    renderSearchFunction={item => <LeakageSensor item={item} />}
                    addUrl={`retranslate/${this.props.server}/zone-leakage-sensor`}
                    addDataIn={item => ({ ZONE_ID: this.id, SENSOR_ID: item.ID })}
                    deleteUrl={this.isUserZoneAdmin && (this.state.card?.LEAKAGES ?? []).length < 2 ? null :
                      item => (`retranslate/${this.props.server}/zone-leakage-sensor/${item.ID_LINK}`)}
                    searchUrl={`retranslate/${this.props.server}/leakage-sensor`}
                    filter={{ noZone: this.id ?? null }}
                    disabled={!this.id}
                  />
                </Tab> : null}
              {canRead(permissions.valve, this.props.serverPermissions) ?
                <Tab eventKey="valve" title="Запорная арматура">
                  <TabContent 
                    cards={this.state.card?.VALVES ?? []}
                    onChangeHandler={() => this.loadAndParentReload(this.id, ['VALVES'])}
                    renderFunction={item => <Valve item={item} onClick={this.isUserZoneAdmin ? null : this.clickModalOpen.bind(this, 'valveModalShow', item.ID)} />}
                    renderSearchFunction={item => <Valve item={item} />}
                    addUrl={`retranslate/${this.props.server}/zone-valve`}
                    addDataIn={item => ({ ZONE_ID: this.id, VALVE_ID: item.ID })}
                    deleteUrl={this.isUserZoneAdmin && (this.state.card?.VALVES ?? []).length < 2 ? null :
                      item => (`retranslate/${this.props.server}/zone-valve/${item.ID_LINK}`)}
                    searchUrl={`retranslate/${this.props.server}/valve`}
                    filter={{ noZone: this.id ?? null }}
                    disabled={!this.id}
                  />
                </Tab> : null}
              {canRead(permissions.valveNbiot, this.props.serverPermissions) ?
                <Tab eventKey="valveNbiot" title="Запорная арматура Nbiot">
                  <TabContent 
                    cards={this.state.card?.VALVES_NBIOT ?? []}
                    onChangeHandler={() => this.loadAndParentReload(this.id, ['VALVES_NBIOT'])}
                    renderFunction={item => <ValveNbiot item={item} onClick={this.isUserZoneAdmin ? null : this.clickModalOpen.bind(this, 'valveNbiotModalShow', item.ID)} />}
                    renderSearchFunction={item => <ValveNbiot item={item} />}
                    addUrl={`retranslate/${this.props.server}/zone-valve-nbiot`}
                    addDataIn={item => ({ ZONE_ID: this.id, VALVE_ID: item.ID })}
                    deleteUrl={this.isUserZoneAdmin && (this.state.card?.VALVES_NBIOT ?? []).length < 2 ? null :
                      item => (`retranslate/${this.props.server}/zone-valve-nbiot/${item.ID_LINK}`)}
                    searchUrl={`retranslate/${this.props.server}/valve-nbiot`}
                    filter={{ noZone: this.id ?? null }}
                    disabled={!this.id}
                  />
                </Tab> : null}
              {this.isUserZoneAdmin ? null :
                <Tab eventKey="admins" title="Администраторы">
                  <TabContent 
                    cards={this.state.card?.ABONENTS ?? []}
                    renderFunction={item => {
                      return (
                        <div>
                          <i className="fas fa-fw fa-eye pointer" title="Просмотреть" onClick={this.clickModalOpen.bind(this, 'abonentModalShow', item.ID)}> </i>&nbsp;&nbsp;
                          <span className="pointer" title="Просмотреть" onClick={this.clickModalOpen.bind(this, 'abonentModalShow', item.ID)}>{item.LOGIN}</span>
                        </div>
                      )
                    }}
                    disabled={!this.id}
                  />
                </Tab>}
            </Tabs>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {this.id ? <Button disabled={this.state.requestProcess} variant="danger" onClick={this.delClick}>Удалить</Button> : null}
          <Button disabled={this.disabledSaveButtons()} variant="primary" onClick={this.saveClick}>Сохранить и продолжить</Button>
          <Button disabled={this.disabledSaveButtons()} variant="primary" onClick={this.saveCloseClick}>Сохранить и закрыть</Button>
          <Button disabled={this.state.requestProcess} variant="secondary" onClick={this.cancelClick}>Отмена</Button>
        </Modal.Footer>

        <Modal show={this.state.mapModalShow} onHide={this.hideMapModal()} size="xl">
          <Map
            coordinates={[this.state.formControls.LATITUDE.value, this.state.formControls.LONGITUDE.value]}
            hideCallback={coordinates => {
              this.coordinatesToState(coordinates)
              this.hideMapModal()()
            }}
          />
        </Modal>

        <Modal show={this.state.abonentModalShow} onHide={this.hideModals} size="xl">
          <AbonentForm
            server={this.props.server}
            id={this.state.thisTabId}
            hideCallback={this.hideModals}
            parentSavedCallback={() => this.load(this.id, ['ABONENTS'])}
            mainUrl='abonent' />
        </Modal>

        <Modal show={this.state.counterModalShow} onHide={this.hideModals} size="xl">
          <CounterForm
            server={this.props.server}
            id={this.state.thisTabId}
            hideCallback={this.hideModals}
            parentReloadCallback={() => this.loadAndParentReload(this.id, ['COUNTERS'])}
            mainUrl='counter' />
        </Modal>

        <Modal show={this.state.valveModalShow} onHide={this.hideModals} size="xl">
          <ValveForm
            server={this.props.server}
            id={this.state.thisTabId}
            hideCallback={this.hideModals}
            parentReloadCallback={() => this.loadAndParentReload(this.id, ['VALVES'])}
            mainUrl='valve' />
        </Modal>

        <Modal show={this.state.valveNbiotModalShow} onHide={this.hideModals} size="xl">
          <ValveNbiotForm
            server={this.props.server}
            id={this.state.thisTabId}
            hideCallback={this.hideModals}
            parentReloadCallback={() => this.loadAndParentReload(this.id, ['VALVES_NBIOT'])}
            mainUrl='valve-nbiot' />
        </Modal>

        <Modal show={this.state.sensorModalShow} onHide={this.hideModals} size="xl">
          <SensorForm
            server={this.props.server}
            id={this.state.thisTabId}
            hideCallback={this.hideModals}
            parentReloadCallback={() => this.loadAndParentReload(this.id, ['SENSORS'])}
            mainUrl='sensor' />
        </Modal>

        <Modal show={this.state.leakageModalShow} onHide={this.hideModals} size="xl">
          <LeakageForm
            server={this.props.server}
            id={this.state.thisTabId}
            hideCallback={this.hideModals}
            parentReloadCallback={() => this.loadAndParentReload(this.id, ['LEAKAGES'])}
            mainUrl='leakage-sensor' />
        </Modal>

        <ConfirmModal {...this.state.confirmModal} />
      </>
    )
  }
}