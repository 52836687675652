import BaseForm from '../BaseForm'

import React from 'react'
import { Button, Form, Modal, Row } from 'react-bootstrap'

import FormControlsRender from '../../../Utils/FormControlsRender'
import { Text, TextArea, Numeric, DatePicker, Checkbox } from '../../../Utils/FormControlsPresets'
import SearchInputObj from '../../../Components/General/SearchInputs/searchInputObj'
import SearchInputAddr from '../../../Components/General/SearchInputs/searchInputAddr'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import {default as TabContent} from '../../../Components/General/Tab'
import Guid from '../../../Components/Guid'
import Map from '../../../Components/Map/Map'
import ZoneForm from '../zones/ZoneForm'
import Zone from '../../../Components/Zone'
import ConfirmModal from '../../../Components/General/ConfirmModal'

import { permissions, canDelete } from '../../ServerPermissions/Permissions'

export default class SensorForm extends BaseForm {
  constructor(props) {
    super(props)

    this.isUserZoneAdmin = this.props.serverPermissions?.isZoneAdmin

    this.state = {
      requestProcess: false,
      card: {},
      formControls: {
        SERIAL_NUMBER: Text('Серийный номер'),
        FACTORY_NUMBER: Text('Заводской номер'),
        COMMENT: TextArea('Служебный комментарий'),
        PRIM: TextArea('Примечание'),
        LATITUDE: Numeric('Коорд. (широта)', -90, 90, /^\-?\d{0,2}(\.?\d{0,6})?$/), //eslint-disable-line
        LONGITUDE: Numeric('Коорд. (долгота)', -180, 180, /^\-?\d{0,3}(\.?\d{0,6})?$/), //eslint-disable-line
        INSTALLATION_HEIGHT: Numeric('Высота установки', 0, 99, /^\-?\d{0,2}(\.?\d{0,2})?$/), //eslint-disable-line
        TEMPERATURE_UP_THRESHOLD: Numeric('Верхний порог температуры, °C'),
        TEMPERATURE_DOWN_THRESHOLD: Numeric('Нижний порог температуры, °C'),
        HUMIDITY_UP_THRESHOLD: Numeric('Верхний порог влажности, %'),
        HUMIDITY_DOWN_THRESHOLD: Numeric('Нижний порог влажности, %'),
        DATEON: DatePicker('Добавлен в систему'),
        DATE_OF_VERIFICATION: DatePicker('Дата поверки'),
        DATE_OF_VERIFICATION_NEXT: DatePicker('Дата след. поверки'),
        DATE_OF_MANUFACTURE: DatePicker('Дата производства'),
        IS_OUTDOOR: Checkbox('Наружное размещение'),
        IS_DEMO: Checkbox('Демонстрационный'),
        THERMOMAPPING: Checkbox('Термокартирование'),
        VERIFICATION_CERTIFICATE: Text('Свид. о поверке'),
        MODEL: {},
        IGNORE_HUMIDITY: Checkbox('Игнорирование влажности'),
      },
      postData: {},
      mapModalShow: false,
      thisZone: null,
      zoneModalShow: false
    }

    this.id = props.id //для обновления ID
  }

  clickZoneOpen = (id = null) => {
    this.setState({ thisZone: id, zoneModalShow: true })
  }

  hideZoneModal = () => {
    this.setState({ thisZone: null, zoneModalShow: false })
  }

  render() {
    return (
      <>
        <Modal.Header closeButton>
          <Modal.Title>{`${this.id ? 'Редактирование' : 'Создание'} датчика`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Guid key={this.id} value={this.id} />
            </Row>
            <Row>
              {FormControlsRender('SERIAL_NUMBER', this.state.formControls, this.onChangeHandler, this.onSaveHandler(), 'col-md-3', { disabled: this.isUserZoneAdmin })}
              {FormControlsRender('FACTORY_NUMBER', this.state.formControls, this.onChangeHandler, this.onSaveHandler(), 'col-md-3', { disabled: this.isUserZoneAdmin })}
              <SearchInputObj
                label="Модель"
                source="sensor-model"
                size='3'
                server={this.props.server}
                render={item => <>{item.TITLE}</>}
                onSelectItem={item => {
                  this.setState({
                    postData: Object.assign({}, this.state.postData, { MODEL: item.ID ?? null })
                  })
                }}
                defaultItem={this.state.card?.MODEL ?? {}}
                errorMessage={this.state.formControls.MODEL?.errorMessage}
                disabled={true}
              />
              {FormControlsRender('DATE_OF_MANUFACTURE', this.state.formControls, this.onChangeDPHandler, this.onSaveHandler(), 'col-md-3', { disabled: this.isUserZoneAdmin })}
            </Row>

            <Row>
              <SearchInputAddr
                key={this.state.card?.ADDRESS?.ID ?? 'address'}
                size='9'
                server={this.props.server}
                closeSearch={this.closeSearchAddr}
                defaultItem={this.state.card?.ADDRESS ?? {}}
                onSelectItem={item => {
                  this.setState({
                    postData: Object.assign({}, this.state.postData, { ADDRESS: item.ID ?? null })
                  })
                }}
                addAddr={this.addAddr}
              />
              {FormControlsRender('IGNORE_HUMIDITY', this.state.formControls, this.onChangeCheckboxHandler, this.onSaveHandler(), 'col-md-3')}
              {/* {FormControlsRender('DATEON', this.state.formControls, this.onChangeDPHandler, this.onSaveHandler(), 'col-md-3', { disabled: true })} */}
            </Row>

            <Row>
              {FormControlsRender('LATITUDE', this.state.formControls, this.onChangeHandler, this.onSaveHandler(), 'col-md-2')}
              {FormControlsRender('LONGITUDE', this.state.formControls, this.onChangeHandler, this.onSaveHandler(), 'col-md-2')}
              <div className="col-md-2"><Button variant="secondary" style={{ marginTop: "32px" }} onClick={this.showMapModal()}>Карта</Button></div>
              {FormControlsRender('IS_OUTDOOR', this.state.formControls, this.onChangeCheckboxHandler, this.onSaveHandler(), 'col-md-3')}
              {FormControlsRender('THERMOMAPPING', this.state.formControls, this.onChangeCheckboxHandler, this.onSaveHandler(), 'col-md-3')}
            </Row>
          </Form>

          <Row>
            {FormControlsRender('TEMPERATURE_UP_THRESHOLD', this.state.formControls, this.onChangeHandler, this.onSaveHandler(), 'col-md-3', { disabled: true })}
            {FormControlsRender('TEMPERATURE_DOWN_THRESHOLD', this.state.formControls, this.onChangeHandler, this.onSaveHandler(), 'col-md-3', { disabled: true })}
            {FormControlsRender('HUMIDITY_UP_THRESHOLD', this.state.formControls, this.onChangeHandler, this.onSaveHandler(), 'col-md-3', { disabled: true })}
            {FormControlsRender('HUMIDITY_DOWN_THRESHOLD', this.state.formControls, this.onChangeHandler, this.onSaveHandler(), 'col-md-3', { disabled: true })}
          </Row>

          <Row>
            {FormControlsRender('INSTALLATION_HEIGHT', this.state.formControls, this.onChangeHandler, this.onSaveHandler(), 'col-md-2')}
            {FormControlsRender('VERIFICATION_CERTIFICATE', this.state.formControls, this.onChangeHandler, this.onSaveHandler(), 'col-md-2', { disabled: this.isUserZoneAdmin })}
            {FormControlsRender('DATE_OF_VERIFICATION', this.state.formControls, this.onChangeDPHandler, this.onSaveHandler(), 'col-md-2', { disabled: this.isUserZoneAdmin })}
            {FormControlsRender('DATE_OF_VERIFICATION_NEXT', this.state.formControls, null, null, 'col-md-3', { disabled: true })}
            {FormControlsRender('IS_DEMO', this.state.formControls, this.onChangeCheckboxHandler, this.onSaveHandler(), 'col-md-3', { disabled: this.isUserZoneAdmin })}
          </Row>

          <Row>
            {FormControlsRender('COMMENT', this.state.formControls, this.onChangeHandler, this.onSaveHandler(), 'col-md-6')}
            {FormControlsRender('PRIM', this.state.formControls, this.onChangeHandler, this.onSaveHandler(), 'col-md-6')}
          </Row>

          <Tabs
            defaultActiveKey="zones"
            id="modem-tabs"
            className="mb-3"
          >
            <Tab eventKey="zones" title="Зоны" className="scrolled">
              <TabContent
                cards={this.state.card?.ZONES ?? []}
                onChangeHandler={() => this.loadAndParentReload(this.id, ['ZONES'])}
                renderFunction={item => <Zone item={item} onClick={this.isUserZoneAdmin ? null : this.clickZoneOpen.bind(this, item.ID)} />}
                renderSearchFunction={item => <Zone item={item} />}
                addUrl={`retranslate/${this.props.server}/zone-sensor`}
                addDataIn={item => ({ SENSOR_ID: this.id, ZONE_ID: item.ID })}
                deleteUrl={this.isUserZoneAdmin && (this.state.card?.ZONES ?? []).length < 2 ? null : 
                  item => (`retranslate/${this.props.server}/zone-sensor/${item.ID_LINK}`)}
                searchUrl={`retranslate/${this.props.server}/zone`}
                filter={{ noSensor: this.id ?? null }}
                disabled={!this.id}
              />
            </Tab>
          </Tabs>
        </Modal.Body>
        <Modal.Footer>
          {this.id && canDelete(permissions.sensor, this.props.serverPermissions) ? 
            <Button disabled={this.state.requestProcess} variant="danger" onClick={this.delClick}>Удалить</Button> : null}
          <Button disabled={this.disabledSaveButtons()} variant="primary" onClick={this.saveClick}>Сохранить и продолжить</Button>
          <Button disabled={this.disabledSaveButtons()} variant="primary" onClick={this.saveCloseClick}>Сохранить и закрыть</Button>
          <Button disabled={this.state.requestProcess} variant="secondary" onClick={this.cancelClick}>Отмена</Button>
        </Modal.Footer>

        <Modal show={this.state.mapModalShow} onHide={this.hideMapModal()} size="xl">
          <Map
            coordinates={[this.state.formControls.LATITUDE.value, this.state.formControls.LONGITUDE.value]}
            hideCallback={coordinates => {
              this.coordinatesToState(coordinates)
              this.hideMapModal()()
            }}
          />
        </Modal>

        <Modal show={this.state.zoneModalShow} onHide={this.hideZoneModal} size="xl">
          <ZoneForm
            server={this.props.server}
            id={this.state.thisZone}
            hideCallback={this.hideZoneModal}
            parentReloadCallback={() => this.loadAndParentReload(this.id, ['ZONES'])}
            mainUrl='zone' />
        </Modal>

        <ConfirmModal {...this.state.confirmModal} />
      </>
    )
  }
}