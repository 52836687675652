import BaseForm from '../BaseForm'

import React from 'react'
import { Button, Form, FormCheck, Modal, Row } from 'react-bootstrap'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import {default as TabContent} from '../../../Components/General/Tab'
import SearchInputObj from '../../../Components/General/SearchInputs/searchInputObj'
import ConfirmModal, { hideConfirmModal } from '../../../Components/General/ConfirmModal'

import { RequestWithToken } from '../../../Components/General/Request'
import { toast } from 'react-toastify'

import FormControlsRender from '../../../Utils/FormControlsRender'
import { Text, TextArea, Password, Checkbox, Select, DatePicker } from '../../../Utils/FormControlsPresets'
import Zone from '../../../Components/Zone'

import { permissions, canRead } from '../../ServerPermissions/Permissions'

export default class AbonentForm extends BaseForm {
  constructor(props) {
    super(props)

    this.isUserZoneAdmin = this.props.serverPermissions?.isZoneAdmin

    this.state = {
      requestProcess: false,
      card: {},
      formControls: {
        LOGIN: Text('Логин'),
        EMAIL: Text('Email'),
        PASSWORD: Password('Пароль', 'заполните поле, если необходимо сменить пароль'),
        SHOW_COMMENTS: Checkbox('Отображать комментарии в СПО'),
        SHOW_ALWAYS_PERS_DATA: Checkbox('Всегда показывать персональные данные'),
        IS_DEMO: Checkbox('Демонстрационная учетная запись'),
        WEB_ROLE: Select('Тип пользователя', [
          ['', ''],
          [0, 'Абонент'],
          [1, 'Администратор организации'],
          [2, 'Супер администратор'],
          [3, 'Технический писатель'],
          [4, 'Оператор ресурса'],
          [99, 'Пользователь ПУД']
        ]),
        ACTIVE_UNTIL: DatePicker('Срок действия уч.записи'),
        DATEON: DatePicker('Дата создания уч.записи'),
        COMMENT: TextArea('Служебный комментарий'),
      },
      presetLinkModal: { show: false, telegram: true, email: false, orgProfile: null },
      postData: {}
    }

    this.id = props.id //для обновления ID
  }

  input = event => {
    this.setState({
      card: Object.assign({}, this.state.card, { [event.target.name]: event.target.value }),
      postData: Object.assign({}, this.state.postData, { [event.target.name]: event.target.value })
    })
  }

  afterLoad = items => {
    const postData = this.state.postData
    const formControls = this.getFormControls(items)
    formControls.SHOW_COMMENTS.checked = postData.SHOW_COMMENTS ?? items?.SETTINGS?.SHOW_COMMENTS ?? false

    const orgProfile = items.PROFILES.find(({ ID }) => ID === localStorage.getItem('orgProfile')) ?? items.PROFILES[0]

    this.setState({
      card: items,
      thisLs: items.LS ? items.LS.ID : null,
      typeSchet: items.TYPE_SCHET,
      formControls,
      presetLinkModal: {
        ...this.state.presetLinkModal,
        orgProfile: orgProfile['ID']
      }
    })
  }

  getDateIn() {
    const postData = this.state.postData
    postData.SETTINGS = { SHOW_COMMENTS: postData.SHOW_COMMENTS }
    delete postData.SHOW_COMMENTS

    return postData
  }

  renderTab(item) {
    return item.TITLE
  }

  checkBeforeSave = () => {
    /** не даем оператору ресурса сохраняться без ресурсов */
    if (this.state.card?.RESOURCES?.length) {
      return true
    } else if (this.state.postData.WEB_ROLE !== undefined && this.state.postData.WEB_ROLE !== 4) {
      return true
    } else if (this.state.postData.WEB_ROLE === undefined && this.state.card?.WEB_ROLE !== 4) {
      return true
    } else {
      toast.error('Необходимо выбрать доступные ресурсы', 'Ошибка', 2000)
      this.setState({ navKey: '2' })
      return false
    }
  }

  saveClick = () => {
    if (this.checkBeforeSave()) {
      this.save()
    }
  }

  saveCloseClick = () => {
    if (this.checkBeforeSave()) {
      this.save(true)
    }
  }

  openPresetLinkModal = () => {
    this.setState({ presetLinkModal: { ...this.state.presetLinkModal, show: true } })
  }

  hidePresetLinkModal = () => {
    this.setState({ presetLinkModal: { ...this.state.presetLinkModal, show: false } })
  }

  allToPresetClick = () => {
    this.setState({
      confirmModal: {
        text: 'Вы действительно хотите включить оповещения для всех устройств в Telegram?',
        handleClose: hideConfirmModal.bind(this),
        handleAccept: hideConfirmModal.bind(this, this.allToPreset.bind(this)),
      }
    })
  }

  async allToPreset() {
    let actions = {}

    if (this.state.presetLinkModal.telegram) {
      actions.telegramBot = true
    }

    const email = this.state.formControls.EMAIL.value
    if (this.state.presetLinkModal.email && email) {
      actions.emails = [email]
    }

    RequestWithToken(`retranslate/${this.props.server}/${this.props.mainUrl}/all-device-to-preset/${this.id}`)
      .post({
        ORG_PROFILE: this.state.presetLinkModal.orgProfile,
        DATA: { actions }
      })
      .then(() => {
        toast.success('Выполнено')
        this.setState({ presetLinkModal: { ...this.state.presetLinkModal, show: false } })
      })
      .catch(() => toast.error('Ошибка'))
  }

  onOrgProfileChange = e => {
    const value = e.target.value
    this.setState({
      presetLinkModal: {
        ...this.state.presetLinkModal,
        orgProfile: value
      }
    })
    localStorage.setItem('orgProfile', value)
  }

  onPresetLinkModalCheckboxChange = e => {
    const name = e.target.name
    const checked = e.target.checked

    this.setState({
      presetLinkModal: {
        ...this.state.presetLinkModal,
        [name]: checked
      }
    })
  }

  createDoubleClick = () => {
    const COPY_FROM_ID = this.state.card.ID

    this.id = null

    let card = this.state.card
    delete(card.ID)
    delete(card.DATEON)
    delete(card.EMAIL)
    delete(card.LIC_SCHET)
    delete(card.LIC_SCHET_NUMBER)
    delete(card.LOGIN)    
    
    this.setState({
      card,
      postData: {
        ...card,
        COPY_FROM_ID,
      },
      formControls: this.getFormControls(card)
    })
    
    toast.info('Данные скопированы. Заполните поля и сохраните дубликат')
  }

  render() {
    return (
      <>
        <Modal.Header closeButton>
          <Modal.Title>{(this.id ? 'Редактирование' : 'Создание') + ' пользователя'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              {FormControlsRender('LOGIN', this.state.formControls, this.onChangeHandler, this.onSaveHandler(), 'col-md-4')}
              {FormControlsRender('EMAIL', this.state.formControls, this.onChangeHandler, this.onSaveHandler(), 'col-md-4')}
              {FormControlsRender('PASSWORD', this.state.formControls, this.onChangeHandler, this.onSaveHandler(), 'col-md-4')}
            </Row>
            <Row>
              {this.isUserZoneAdmin || [0, 2, 3].includes(this.state.card?.WEB_ROLE) ? null :
                <SearchInputObj
                  label="Организация"
                  source="org"
                  server={this.props.server}
                  onSelectItem={item => {
                    this.setState({
                      postData: Object.assign({}, this.state.postData, { ORG: item.ID ?? null })
                    })
                  }}
                  defaultItem={{
                    ID: this.state.card?.ORG,
                    TITLE: this.state.card?.ORG_TITLE
                  }}
                />}
              {[0, 2, 3].includes(this.state.card?.WEB_ROLE) ? null :
                <SearchInputObj
                  label="Зона"
                  source="zone"
                  server={this.props.server}
                  render={item => <Zone item={item} />}
                  onSelectItem={item => {
                    this.setState({
                      postData: Object.assign({}, this.state.postData, { ZONES: item.ID ?? null })
                    })
                  }}
                  defaultItem={{
                    ID: this.state.card?.ZONES,
                    TITLE: this.state.card?.ZONES_TITLE,
                    TYPE_POINT_IMAGE_NUMBER: this.state.card?.ZONES_TYPE_POINT_IMAGE_NUMBER
                  }}
                />}
              {this.isUserZoneAdmin || [2, 3].includes(this.state.card?.WEB_ROLE) ? null :
                  <SearchInputObj
                    label="Лицевой счёт"
                    source="lic-schet"
                    server={this.props.server}
                    onSelectItem={item => {
                      this.setState({
                        postData: Object.assign({}, this.state.postData, { LIC_SCHET: item.ID ?? null })
                      })
                    }}
                    defaultItem={{
                      ID: this.state.card?.LIC_SCHET,
                      TITLE: this.state.card?.LIC_SCHET_NUMBER
                    }}
                  />}


              {this.isUserZoneAdmin ? null :FormControlsRender('WEB_ROLE', this.state.formControls, this.onChangeHandler, this.onSaveHandler(), 'col-md-4')}

              {FormControlsRender('ACTIVE_UNTIL', this.state.formControls, this.onChangeDPHandler, this.onSaveHandler(), 'col-md-4')}

              {FormControlsRender('DATEON', this.state.formControls, this.onChangeDPHandler, this.onSaveHandler(), 'col-md-4', { disabled: true })}

            </Row>

            <Row>
              {(!this.isUserZoneAdmin && [1,2,4].includes(this.state.card?.WEB_ROLE)) ?
                <>
                  {FormControlsRender('SHOW_COMMENTS', this.state.formControls, () => this.onChangeCheckboxHandler('SETTINGS'), this.onSaveHandler(), 'col-md-4')}
                  {FormControlsRender('SHOW_ALWAYS_PERS_DATA', this.state.formControls, this.onChangeCheckboxHandler, this.onSaveHandler(), 'col-md-4')}
                  {FormControlsRender('IS_DEMO', this.state.formControls, this.onChangeCheckboxHandler, this.onSaveHandler(), 'col-md-4')}
                </>
                : null}
            </Row>

            <Row>
              {FormControlsRender('COMMENT', this.state.formControls, this.onChangeHandler, this.onSaveHandler(), 'col-md-12')}
            </Row>

            <div style={{ height: '15px' }}></div>

            <span style={{ display: this.state.card?.WEB_ROLE === 3 ? 'none' : null }}>

              <Tabs
                id="abonent-tabs"
                className="mb-3"
              >
                <Tab eventKey="spoModules" title="Модули СПО">
                  <TabContent
                    cards={this.state.card?.MODULES ?? []}
                    onChangeHandler={() => this.loadAndParentReload(this.id, ['MODULES'])}
                    renderFunction={this.renderTab}
                    renderSearchFunction={this.renderTab}
                    addUrl={`retranslate/${this.props.server}/web-module-abonent`}
                    addDataIn={item => ({ OWNER: this.id, MODULE: item.ID })}
                    deleteUrl={item => (`retranslate/${this.props.server}/web-module-abonent/${item.ID_LINK}`)}
                    searchUrl={`retranslate/${this.props.server}/web-module`}
                    filter={{ notOwner: this.id ?? null }}
                    disabled={!this.id}
                  />
                </Tab>

                {canRead(permissions.counter, this.props.serverPermissions) ?
                <Tab eventKey="resources" title="Ресурсы">
                  <TabContent
                    cards={this.state.card?.RESOURCES ?? []}
                    onChangeHandler={() => this.loadAndParentReload(this.id, ['RESOURCES'])}
                    renderFunction={item => item.RES_NAME ?? item.TITLE}
                    renderSearchFunction={item => item.RES_NAME ?? item.TITLE}
                    addUrl={`retranslate/${this.props.server}/abonent-type-counter`}
                    addDataIn={item => ({ OWNER: this.id, TYPE_COUNTERS: item.ID })}
                    deleteUrl={item => (`retranslate/${this.props.server}/abonent-type-counter/${item.ID_LINK}`)}
                    searchUrl={`retranslate/${this.props.server}/type-counter`}
                    filter={{ notOwner: this.id ?? null }}
                    disabled={!this.id}
                  />
                </Tab> : null}
              </Tabs>
            </span>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={this.state.requestProcess || !this.state.card?.ZONES || Object.keys(this.state.postData).length !== 0}
            variant="secondary" onClick={this.openPresetLinkModal} style={{ marginRight: 'auto' }}>
            Пресет для всех устройств
          </Button>
          {this.id ? <>
            <Button disabled={this.state.requestProcess} variant="danger" onClick={this.delClick}>Удалить</Button>
            <Button disabled={this.state.requestProcess} variant="secondary" onClick={this.createDoubleClick}>Создать дубликат</Button>
          </> : null}          
          <Button disabled={this.disabledSaveButtons()} variant="primary" onClick={this.saveClick}>Сохранить и продолжить</Button>
          <Button disabled={this.disabledSaveButtons()} variant="primary" onClick={this.saveCloseClick}>Сохранить и закрыть</Button>
          <Button disabled={this.state.requestProcess} variant="secondary" onClick={this.cancelClick}>Отмена</Button>
        </Modal.Footer>

        <Modal show={this.state.presetLinkModal.show} onHide={(this.hidePresetLinkModal)}>
          <Modal.Header closeButton>
            <Modal.Title>Пресет для всех устройств</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <label>Профиль организации</label>
            <Form.Select aria-label="Выберите профиль организации" value={this.state.presetLinkModal.orgProfile ?? ''} onChange={this.onOrgProfileChange}>
              {(this.state.card?.PROFILES ?? []).map(el => {
                return (<option key={el.ID} value={el.ID}>{el.APP_TITLE}</option>)
              })}
            </Form.Select>
            <br />
            <FormCheck label='Telegram' name='telegram' checked={this.state.presetLinkModal.telegram}
              onChange={this.onPresetLinkModalCheckboxChange}
              title={`Для получения уведомлений пользователю необходимо авторизоваться в боте`} />
            <FormCheck disabled={!this.state.formControls.EMAIL.value} label='Email' name='email' checked={this.state.presetLinkModal.email}
              onChange={this.onPresetLinkModalCheckboxChange}
              title={`Используется адрес пользователя ${this.state.formControls.EMAIL.value ?? ''}`} />
            <br />
            <small>* Будет создан (или изменен, если создавался ранее) пресет и привязан ко всем устройствам пользователя</small>
          </Modal.Body>
          <Modal.Footer>
            <Button disabled={this.state.presetLinkModal.telegram === false && this.state.presetLinkModal.email === false}
              variant="primary" onClick={this.allToPresetClick}>
              Применить
            </Button>
            <Button disabled={this.state.requestProcess} variant="secondary" onClick={this.hidePresetLinkModal}>Отмена</Button>
          </Modal.Footer>
        </Modal>

        <ConfirmModal {...this.state.confirmModal} />
      </>
    )
  }
}