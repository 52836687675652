import BaseForm from '../BaseForm'

import React from 'react'
import { Button, Form, Modal, Nav, Row } from 'react-bootstrap'

import FormControlsRender from '../../../Utils/FormControlsRender'
import { Text, TextArea, Numeric, DatePicker } from '../../../Utils/FormControlsPresets'
import SearchInputObj from '../../../Components/General/SearchInputs/searchInputObj'
import SearchInputAddr from '../../../Components/General/SearchInputs/searchInputAddr'
import Tab from '../../../Components/General/Tab'
import Guid from '../../../Components/Guid'
import Map from '../../../Components/Map/Map'
import ZoneForm from '../zones/ZoneForm'
import Zone from '../../../Components/Zone'
import ConfirmModal from '../../../Components/General/ConfirmModal'

export default class SensorForm extends BaseForm {
  constructor(props) {
    super(props)

    this.state = {
      navKey: '1',
      requestProcess: false,
      card: {},
      formControls: {
        IDENTIFIER: Text('Идентификатор устройства'),
        COMMENT: TextArea('Служебный комментарий'),
        PRIM: TextArea('Примечание'),
        LATITUDE: Numeric('Коорд. (широта)', -90, 90, /^\-?\d{0,2}(\.?\d{0,6})?$/), //eslint-disable-line
        LONGITUDE: Numeric('Коорд. (долгота)', -180, 180, /^\-?\d{0,3}(\.?\d{0,6})?$/), //eslint-disable-line
        DATEON: DatePicker('Добавлен в систему'),
        MODEL: {}
      },
      postData: {},
      mapModalShow: false,
      thisZone: null,
      zoneModalShow: false
    }

    this.id = props.id //для обновления ID
  }

  clickZoneOpen = (id = null) => this.setState({ thisZone: id, zoneModalShow: true })

  hideZoneModal = () => this.setState({ thisZone: null, zoneModalShow: false })

  render() {
    return (
      <>
        <Modal.Header closeButton>
          <Modal.Title>{`${this.id ? 'Редактирование' : 'Создание'} датчика`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Guid key={this.id} value={this.id} />
            </Row>
            <Row>
              {FormControlsRender('IDENTIFIER', this.state.formControls, this.onChangeHandler, this.onSaveHandler(), 'col-md-4')}
              <SearchInputObj
                label="Модель"
                source="magnetic-contact-sensor-model"
                size='4'
                server={this.props.server}
                render={item => <>{item.TITLE}</>}
                onSelectItem={item => {
                  this.setState({
                    postData: Object.assign({}, this.state.postData, { MODEL: item.ID ?? null })
                  })
                }}
                defaultItem={this.state.card?.MODEL ?? {}}
                errorMessage={this.state.formControls.MODEL?.errorMessage}
                disabled={true}
              />
              {FormControlsRender('DATEON', this.state.formControls, this.onChangeDPHandler, this.onSaveHandler(), 'col-md-4', { disabled: true })}
            </Row>

            <Row>
              <SearchInputAddr
                key={this.state.card?.ADDRESS?.ID ?? 'address'}
                size='12'
                server={this.props.server}
                closeSearch={this.closeSearchAddr}
                defaultItem={this.state.card?.ADDRESS ?? {}}
                onSelectItem={item => {
                  this.setState({
                    postData: Object.assign({}, this.state.postData, { ADDRESS: item.ID ?? null })
                  })
                }}
                addAddr={this.addAddr}
              />
            </Row>

            <Row>
              {FormControlsRender('LATITUDE', this.state.formControls, this.onChangeHandler, this.onSaveHandler(), 'col-md-4')}
              {FormControlsRender('LONGITUDE', this.state.formControls, this.onChangeHandler, this.onSaveHandler(), 'col-md-4')}
              <div className="col-md-4"><Button variant="secondary" style={{ marginTop: "32px" }} onClick={this.showMapModal()}>Карта</Button></div>
            </Row>
          </Form>

          <Row>
            {FormControlsRender('PRIM', this.state.formControls, this.onChangeHandler, this.onSaveHandler(), 'col-md-6')}
            {FormControlsRender('COMMENT', this.state.formControls, this.onChangeHandler, this.onSaveHandler(), 'col-md-6')}
          </Row>

          <Nav variant="tabs" defaultActiveKey={this.state.navKey} onSelect={key => this.setState({ navKey: key })}>
            <Nav.Item>
              <Nav.Link eventKey="1">Зоны</Nav.Link>
            </Nav.Item>
          </Nav>

          <div className="tab-content tabContent" id="nav-tabContent">
            <span style={{ display: this.state.navKey !== '1' ? 'none' : null }}>
              <Tab
                cards={this.state.card?.ZONES ?? []}
                onChangeHandler={() => this.loadAndParentReload(this.id, ['ZONES'])}
                renderFunction={item => <Zone item={item} onClick={this.clickZoneOpen.bind(this, item.ID)} />}
                renderSearchFunction={item => <Zone item={item} />}
                addUrl={`retranslate/${this.props.server}/zone-magnetic-contact-sensor`}
                addDataIn={item => ({ SENSOR_ID: this.id, ZONE_ID: item.ID })}
                deleteUrl={item => (`retranslate/${this.props.server}/zone-magnetic-contact-sensor/${item.ID_LINK}`)}
                searchUrl={`retranslate/${this.props.server}/zone`}
                filter={{ noSensor: this.id ?? null }}
                disabled={!this.id}
              />
            </span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {this.id ? <Button disabled={this.state.requestProcess} variant="danger" onClick={this.delClick}>Удалить</Button> : null}
          <Button disabled={this.disabledSaveButtons()} variant="primary" onClick={this.saveClick}>Сохранить и продолжить</Button>
          <Button disabled={this.disabledSaveButtons()} variant="primary" onClick={this.saveCloseClick}>Сохранить и закрыть</Button>
          <Button disabled={this.state.requestProcess} variant="secondary" onClick={this.cancelClick}>Отмена</Button>
        </Modal.Footer>

        <Modal show={this.state.mapModalShow} onHide={this.hideMapModal()} size="xl">
          <Map
            coordinates={[this.state.formControls.LATITUDE.value, this.state.formControls.LONGITUDE.value]}
            hideCallback={coordinates => {
              this.coordinatesToState(coordinates)
              this.hideMapModal()()
            }}
          />
        </Modal>

        <Modal show={this.state.zoneModalShow} onHide={this.hideZoneModal} size="xl">
          <ZoneForm
            server={this.props.server}
            id={this.state.thisZone}
            hideCallback={this.hideZoneModal}
            parentReloadCallback={() => this.loadAndParentReload(this.id, ['ZONES'])}
            mainUrl='zone' />
        </Modal>

        <ConfirmModal {...this.state.confirmModal}/>
      </>
    )
  }
}