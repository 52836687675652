import BaseForm from '../BaseForm'

import React from 'react'
import { Button, Form, Modal, Nav, Row } from 'react-bootstrap'

import FormControlsRender from '../../../Utils/FormControlsRender'
import { Text, TextArea, DatePicker } from '../../../Utils/FormControlsPresets'
import SearchInputObj from '../../../Components/General/SearchInputs/searchInputObj'
import Tab from '../../../Components/General/Tab'
import Guid from '../../../Components/Guid'
import Zone from '../../../Components/Zone'
import ConfirmModal from '../../../Components/General/ConfirmModal'
import SensorForm from '../sensors/SensorForm'
import Sensor from '../../../Components/Devices/Sensor'

export default class PointForm extends BaseForm {
  constructor(props) {
    super(props)

    this.state = {
      navKey: '1',
      requestProcess: false,
      card: {},
      formControls: {
        TITLE: Text('Наименование'),
        PRIM: TextArea('Примечание'),
        DATEON: DatePicker('Дата создания'),
        ZONE_ID: {},
      },
      postData: {},
      thisTabId: null,
      sensorModalShow: false,
    }

    this.id = props.id //для обновления ID
  }

  clickModalOpen = (modalVariableShow, id = null) => {
    this.setState({ thisTabId: id, [modalVariableShow]: true })
  }

  hideModals = () => {
    this.setState({
      thisTabId: null,
      sensorModalShow: false,
    })
  }

  render() {
    return (
      <>
        <Modal.Header closeButton>
          <Modal.Title>{`${this.id ? 'Редактирование' : 'Создание'} датчика протечки`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Guid key={this.id} value={this.id} />
            </Row>
            <Row>
              {FormControlsRender('TITLE', this.state.formControls, this.onChangeHandler, this.onSaveHandler(), 'col-md-4')}
              <SearchInputObj
                label="Зона"
                source="zone"
                server={this.props.server}
                render={item => <Zone item={item} />}
                onSelectItem={item => {
                  this.setState({
                    postData: Object.assign({}, this.state.postData, { ZONE_ID: item.ID ?? null })
                  })
                }}
                defaultItem={{
                  ID: this.state.card.ZONE?.ID,
                  TITLE: this.state.card.ZONE?.TITLE
                }}
                errorMessage={this.state.formControls.ZONE_ID?.errorMessage}
              />
              {FormControlsRender('DATEON', this.state.formControls, this.onChangeDPHandler, this.onSaveHandler(), 'col-md-4', { disabled: true })}
            </Row>
            <Row>
              {FormControlsRender('PRIM', this.state.formControls, this.onChangeHandler, this.onSaveHandler(), 'col-md-12')}
            </Row>
          </Form>

          <Nav variant="tabs" defaultActiveKey={this.state.navKey} onSelect={key => this.setState({ navKey: key })}>
            <Nav.Item>
              <Nav.Link eventKey="1">Датчики</Nav.Link>
            </Nav.Item>
          </Nav>

          <div className="tab-content tabContent" id="nav-tabContent">
            <span style={{ display: this.state.navKey !== '1' ? 'none' : null }}>
              <Tab
                cards={this.state.card?.SENSORS ?? []}
                onChangeHandler={() => this.loadAndParentReload(this.id, ['SENSORS'])}
                renderFunction={item => <Sensor item={item} onClick={this.clickModalOpen.bind(this, 'sensorModalShow', item.ID)} />}
                renderSearchFunction={item => <Sensor item={item} />}
                addUrl={`retranslate/${this.props.server}/point-sensor`}
                addDataIn={item => ({ LIC_SCHET: this.id, SENSOR: item.ID })}
                deleteUrl={item => (`retranslate/${this.props.server}/point-sensor/${item.ID_LINK}`)}
                searchUrl={`retranslate/${this.props.server}/sensor`}
                filter={{ noLs: this.id ?? null }}
                disabled={!this.id}
              />
            </span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {this.id ? <Button disabled={this.state.requestProcess} variant="danger" onClick={this.delClick}>Удалить</Button> : null}
          <Button disabled={this.disabledSaveButtons()} variant="primary" onClick={this.saveClick}>Сохранить и продолжить</Button>
          <Button disabled={this.disabledSaveButtons()} variant="primary" onClick={this.saveCloseClick}>Сохранить и закрыть</Button>
          <Button disabled={this.state.requestProcess} variant="secondary" onClick={this.cancelClick}>Отмена</Button>
        </Modal.Footer>


        <Modal show={this.state.sensorModalShow} onHide={this.hideModals} size="xl">
          <SensorForm
            server={this.props.server}
            id={this.state.thisTabId}
            hideCallback={this.hideModals}
            parentReloadCallback={() => this.loadAndParentReload(this.id, ['SENSORS'])}
            mainUrl='sensor' />
        </Modal>

        <ConfirmModal {...this.state.confirmModal} />
      </>
    )
  }
}